import Guide from "../Guide";
import {useEffect, useState} from 'react';
import * as formValidationRules from "../../../Constants/formValidationRules";
import {useAppSelector} from "../../../hooks";
import {StartGuideDataLonEa, useMigrationActionCreator} from "../../../Actions/MigrationActionCreator";
import {SourceType} from "../../Enums/CommonEnums";
import LonEaCustomerInfo from "./LonEaCustomerInfo";
import LonEaConfirmInformation from "./LonEaConfirmInformation";
import { productNames } from "../../../Constants/productNames";

export default function StartGuideLonEA() {
    const startGuideState = useAppSelector(state => state.lonEaStartGuide).startGuide;
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
    const migration = useAppSelector(state => state.migration).migrationDetails
    const migrationActions = useMigrationActionCreator();
    const isHandelsbanken = startGuideState.customerInfo.bank.name === "Handelsbanken";

    useEffect(() => {
            migrationActions.getSmbMigrationDetails(SourceType.Lon)
    }, [])
    
    if(!migration)
        return null

    const validateCustomerInfoStep = () => {
        const { customerInfo } = startGuideState;
        const errors: { [key: string]: string } = {};

        formValidationRules.customerInfoLonEa.forEach(({ inputId, rule, message }) => {
            const value = customerInfo[inputId as keyof typeof customerInfo];

            if (inputId === "bank") {
                if (!value || !(value as any).id ) {
                    errors[inputId] = message;
                }
            }

            if(!((rule === 'required' && value) || (new RegExp(rule).test((value ?? '').toString())))) {
                errors[inputId] = message;
            }
        });
        
        if (customerInfo.startDate && customerInfo.endDate && customerInfo.startDate >= customerInfo.endDate) {
            errors["startDate"] = "Startdatum måste vara tidigare än slutdatum";
            errors["endDate"] = "Slutdatum måste vara senare än startdatum";
        }
        
        if (isHandelsbanken && customerInfo.accountNumber && customerInfo.accountNumber.toString().length !== 9) {
            errors["accountNumber"] = "Kontonummer måste vara 9 siffror.";
        } else if (!isHandelsbanken && customerInfo.accountNumber && customerInfo.accountNumber.toString().length > 35) {
            errors["accountNumber"] = "Kontonummer får vara max 35 siffror";
        }
        
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };
    
    const target = productNames.VLS;
    
    const steps = [
        {
            id: 1,
            label: 'Kunduppgifter',
            content: <LonEaCustomerInfo customerInfo={startGuideState.customerInfo} validationErrors={validationErrors} />,
            complete: (startGuideState.isCustomerInfoComplete),
            validate: validateCustomerInfoStep,
        },
        {
            id: 2,
            label: 'Slutför',
            content: <LonEaConfirmInformation startGuide={startGuideState} />,
            complete: true,
            validate: () => true
        }
    ];

    const saveStartGuideData = (goToNextStep: () => void) => {

        const data:StartGuideDataLonEa = {
            id: migration.id,
            company: {
                startDate: startGuideState.customerInfo.startDate,
                endDate: startGuideState.customerInfo.endDate,
                bank: startGuideState.customerInfo.bank,
                accountNumber: startGuideState.customerInfo.accountNumber,
            },
        }

        migrationActions.saveStartGuideDataLonEa(data, goToNextStep)
    };
    
    return <Guide steps={steps} onCompleteClick={saveStartGuideData}  target={target}/>;
}