import { useEffect, useState } from "react";
import { useInternalActionCreator } from "../../Actions/InternalActionCreator";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { FeatureFlag } from "./Interfaces";
import { SourceType } from "../Enums/CommonEnums";
import { modalTypes } from "../../Constants/modalTypes";
import AddFeatureFlagModal from "./AddFeatureFlagModal";
import { onToggleModal } from "../../Reducers/modalReducer";
import { updateFeatureFlag } from "../../Reducers/featureFlagReducer";
import MassDeleteFeatureFlagsModal from "./MassDeleteFeatureFlagsModal";
import { useFeatureFlagActionCreator } from "../../Actions/FeatureFlagActionCreator";

export default function FeatureFlagList() {
    const featureFlagAction = useFeatureFlagActionCreator();
    const dispatch = useAppDispatch();

    const featureFlags = useAppSelector((state) => state.featureFlag.featureFlags);
    const customerNamesAndIds = useAppSelector((state) => state.featureFlag.MigrationNamesAndIds);
    const internalUsers = useAppSelector((state) => state.users.internalUsers);
    const activeModal = useAppSelector((state) => state.modal.modal);

    useEffect(() => {
        featureFlagAction.getFeatureFlags();

    }, []);

    const getCustomerNameById = (id: string): string | undefined => {
        const customer = customerNamesAndIds.find(c => c.id === id);
        return customer ? customer.customerName : undefined;
    };

    const getInternalUserNameById = (id: string): string | undefined => {
        const user = internalUsers.find(u => u.id === id);
        return user ? user.name : undefined;
    }

    const getSourceTypeName = (sourceType: SourceType | undefined): string => {
        if (sourceType === undefined) return '';
        return SourceType[sourceType];
    };

    function deleteFeatureFlag(id?: number) {
        if (id === undefined || id === null) {
            return null;
        }
        return (
            <span className="vismaicon vismaicon-delete cursorpointer" onClick={() => featureFlagAction.deleteFeatureFlag(id)}></span>
        );
    }

    const setStatus = async (flag: FeatureFlag, status: boolean) => {
        const updatedFlag = { ...flag, enabled: status };
        try {
            await featureFlagAction.updateFeatureFlag(updatedFlag);
            dispatch(updateFeatureFlag(updatedFlag));
        } catch (error) {
            console.error("Failed to update feature flag:", error);
        }
    };

    function renderFeatureFlags() {
        return (
            <div className="panel mt-16 mr-32 ml-32">
                <table className="table table-stripe">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Namn</th>
                            <th scope="col">Tillhörande kund</th>
                            <th scope="col">Sourcetype</th>
                            <th scope="col">Ägare</th>
                            <th scope="col">Aktiv</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {featureFlags.map((flag) => (
                            <tr key={flag.id}>
                                <td scope="row"><span className="vismaicon vismaicon-edit cursorpointer"></span></td>
                                <td>{flag.name}</td>
                                <td>{flag.migrationId ? getCustomerNameById(flag.migrationId) : ''}</td>
                                <td>{getSourceTypeName(flag.sourceType)}</td>
                                <td>{flag.ownerId ? getInternalUserNameById(flag.ownerId) : ''}</td>
                                <td>
                                    <div className="switch">
                                        <input
                                            id={`option-${flag.id}`}
                                            type="checkbox"
                                            name="option"
                                            checked={flag.enabled}
                                            onChange={(e) => setStatus(flag, e.target.checked)}
                                        />
                                        <label htmlFor={`option-${flag.id}`} className="togglemark">
                                            {flag.enabled}
                                        </label>
                                    </div>
                                </td>
                                <td className="">{deleteFeatureFlag(flag.id)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-end pb-12 mr-32">
                <div>
                    <button className="btn btn-primary" onClick={() => dispatch(onToggleModal(modalTypes.addFeatureFlagModal))}>Lägg till</button>
                </div>
                <div className="pl-16">
                    <button className="btn btn-primary btn-destructive" onClick={() => dispatch(onToggleModal(modalTypes.massDeleteFeatureFlagsModal))}>Rensa</button>
                </div>
            </div>
            <div className="col-12 pl-0 pr-0">
                {renderFeatureFlags()}
            </div>
            {activeModal === modalTypes.addFeatureFlagModal && <AddFeatureFlagModal />}
            {activeModal === modalTypes.massDeleteFeatureFlagsModal && <MassDeleteFeatureFlagsModal />}
        </div>
    );
};
