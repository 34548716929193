import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MigrationCount } from './internalMigrationReducer';

export interface Searches {
    [searchInput: string]: any;
}
interface SearchState {
    searches: Searches;
    searchResultsCount: number;
    migrationSearchCount: MigrationCount[];
}

const initialState: SearchState = {
    searches: {},
    searchResultsCount: 0,
    migrationSearchCount: [],
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        storeSearchTerm: (state, action: PayloadAction<{ searchInput: string | null, id: string }>) => {
            state.searches[action.payload.id] = action.payload.searchInput;
        },
        storeMigrationSearchCount: (state, action: PayloadAction<MigrationCount[]>) => {
            state.migrationSearchCount = action.payload;
        },
    },
});

export const { storeSearchTerm, storeMigrationSearchCount } = searchSlice.actions;
export default searchSlice.reducer;