import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useMigrationActionCreator} from "../../../Actions/MigrationActionCreator";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {useEffect, useState} from "react";
import DropDown from "../../Helpers/Dropdown";
import {LonEaStartGuideObject, setLonEaCustomerInfoValue} from "../../../Reducers/lonEaStartGuideReducer";
import "../../Helpers/CSS/DatePicker.css";
import RenderInputError from "../../SharedComponents/RenderInputError";

interface Props {
    customerInfo: LonEaStartGuideObject['customerInfo'];
    validationErrors: { [key: string]: string };
}

export default function LonEaCustomerInfo(props: Props) {
    const {customerInfo, validationErrors} = props;
    const migrationActions = useMigrationActionCreator();
    const bankListState = useAppSelector(state => state.bankList.bankList);
    const [selectedBank, setSelectedBank] = useState<any>(customerInfo?.bank?.id);

    const dispatch = useAppDispatch();

    useEffect(() => {
        migrationActions.getAllBanks();

        if (customerInfo.startDate) {
            onFieldChange('startDate', customerInfo.startDate);
        } else {
            const firstDayOfMonthLastYear = new Date();
            firstDayOfMonthLastYear.setFullYear(firstDayOfMonthLastYear.getFullYear() - 1, firstDayOfMonthLastYear.getMonth(), 1);
            onFieldChange('startDate', firstDayOfMonthLastYear);
        }

        if (customerInfo.endDate) {
            onFieldChange('endDate', customerInfo.endDate);
        } else {
            const lastDayOfMonth = new Date();
            lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1, 0);
            onFieldChange('endDate', lastDayOfMonth);
        }
    }, [])

    const onFieldChange = (field: keyof Props['customerInfo'], value: any) => {
        dispatch(setLonEaCustomerInfoValue({field: field, value: value}));
    };

    const renderAsterisk = (fieldName: keyof Props['customerInfo']) => {
        if (validationErrors && validationErrors[fieldName]) {
            return <span style={{color: 'red'}}>*</span>;
        }
        return null;
    };

    function getBankValue(id: any) {
        return bankListState.find(x => x.id === id);
    }

    return (
        <div className="d-flex justify-content-around py-32 flex-row">
            <div style={{width: "60%"}}>
                <div>
                    <p className="m-0">
                        {renderAsterisk("startDate")}
                        Räkenskapsår startdatum
                    </p>
                    <div className={`form-group ${validationErrors['startDate'] ? "has-error" : ""}`}>
                        <DatePicker
                            showIcon
                            calendarIconClassName={"react-datepicker__custom-calendar-icon"}
                            toggleCalendarOnIconClick
                            selected={customerInfo.startDate}
                            onChange={(date) => date && onFieldChange('startDate', date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control">
                        </DatePicker>
                        {validationErrors['startDate'] && (
                            <>
                                <RenderInputError validationError={validationErrors['startDate']}/>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("endDate")}
                        Räkenskapsår slutdatum
                    </p>
                    <div className={`form-group ${validationErrors['endDate'] ? "has-error" : ""}`}>
                        <DatePicker
                            showIcon
                            calendarIconClassName={"react-datepicker__custom-calendar-icon"}
                            toggleCalendarOnIconClick
                            selected={customerInfo.endDate}
                            onChange={(date) => date && onFieldChange('endDate', date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            filterDate={(date: Date) => date.getDate() === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}
                            onKeyDown={(e) => e.preventDefault()}>
                        </DatePicker>
                        {validationErrors['endDate'] && (
                            <>
                                <RenderInputError validationError={validationErrors['endDate']}/>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("bank")}
                        Bank
                    </p>
                    <DropDown list={bankListState}
                              elementWidth="12"
                              padding="p-0"
                              blankValue="Välj"
                              value={customerInfo.bank?.id}
                              onChange={(value: any) => {
                                  setSelectedBank(value);
                                  onFieldChange("bank", getBankValue(value));
                              }}
                              blankOption={true}
                              error={validationErrors.bank}/>
                </div>
                <div className={`form-group ${validationErrors['accountNumber'] ? "has-error" : ""}`}>
                    <p className="m-0">
                        {renderAsterisk("accountNumber")}
                        Kontonummer
                    </p>
                    <div className="col-md-12 col-xs-10 p-0">
                        <input
                            type="text"
                            className="form-control"
                            value={customerInfo.accountNumber ?? ""}
                            onChange={(e) => onFieldChange("accountNumber", e.target.value)}
                            onKeyDown={(e) => {
                                if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Delete') {
                                    e.preventDefault();
                                }
                            }}
                        >
                        </input>
                        {validationErrors['accountNumber'] && (
                            <>
                                <RenderInputError validationError={validationErrors['accountNumber']}/>
                            </>
                        )}
                    </div>
                    {selectedBank === '01568520-cdc9-499f-b08e-82832ccb36dd' &&
                        <p className="text-disabled font-13">Kontonumret måste vara nio siffror långt.<br/>Kontonummer
                            med färre än nio siffror fylls ut med 0 före kontonumret.</p>}
                </div>
            </div>
        </div>
    );
}