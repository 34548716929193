import { useHttp } from "../../Utils/httpUtils";
import { FeatureFlag } from "../Components/FeatureFlags/Interfaces";
import { showAlert } from "../Reducers/alertReducer";
import { setFeatureFlags, setAvailableFeatureFlags, addFlag, removeFeatureFlag, removeFeatureFlags, setSelectedFeatureFlag } from "../Reducers/featureFlagReducer";
import { useAppDispatch } from "../hooks";
import { useInternalActionCreator } from "./InternalActionCreator";

export const useFeatureFlagActionCreator = () => {
    const dispatch = useAppDispatch();
    const { request } = useHttp();
    const internalAction = useInternalActionCreator();

    const getFeatureFlags = () => {
        request(`/api/FeatureFlag/GetFeatureFlags`, null, (data: FeatureFlag[]) => {
            dispatch(setFeatureFlags(data))
            internalAction.getAllMigrationNamesAndIds();
        });
    };

    const getAvailableFeatureFlags = () => {
        request(`/api/FeatureFlag/GetAvailableFlags`, null, (data: string[]) => dispatch(setAvailableFeatureFlags(data)))
    };

    const addFeatureFlag = (featureFlag: FeatureFlag) => {
        const httpRequest: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(featureFlag),
        };

        request(`/api/FeatureFlag/AddFeatureFlag`, httpRequest, (data: number) => {
            dispatch(showAlert({ title: "Success", message: "Feature flag added successfully", style: "top", type: "success" }));
            featureFlag.id = data;
            dispatch(addFlag(featureFlag));
        });
    };

    const deleteFeatureFlag = (featureFlagId: number) => {
        request(`/api/FeatureFlag/DeleteFeatureFlag?featureFlagId=${featureFlagId}`, { method: 'DELETE' }, () => {
            dispatch(removeFeatureFlag(featureFlagId));
        });
    }

    const deleteFeatureFlags = (featureFlagIds: number[]) => {
        const httpRequest: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(featureFlagIds),
        };

        request(`/api/FeatureFlag/DeleteFeatureFlags`, httpRequest, () => {
            dispatch(removeFeatureFlags(featureFlagIds));
        });
    }

    const getFeatureFlagById = (flagId: number) => {
        request(`/api/FeatureFlag/GetFeatureFlag/${flagId}`, null, (data: FeatureFlag) => {
            dispatch(setSelectedFeatureFlag(data));
        });
    }

    const updateFeatureFlag = (featureFlag: FeatureFlag) => {
        const httpRequest: RequestInit = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(featureFlag),
        };

        request(`/api/FeatureFlag/UpdateFeatureFlag`, httpRequest, () => {
            dispatch(showAlert({ title: "Success", message: "Feature flag updated successfully", style: "top", type: "success" }));
        });
    };

    return {
        getFeatureFlags,
        addFeatureFlag,
        deleteFeatureFlag,
        deleteFeatureFlags,
        getFeatureFlagById,
        getAvailableFeatureFlags,
        updateFeatureFlag
    }
};