import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogType, SendStatus } from "../Components/Enums/LogType";
import { EaccountingVariant, MigrationStatus, MigrationType, SourceType } from "../Components/Enums/CommonEnums";
import { CollaborationOption, Company } from "./startguideReducer";
import { UploadedFile } from "../Components/InternalMigrationDetails/MigrationUpload";
import { Competitor, CompetitorConfig } from "./manualMigrationReducer";
import { PaginationProps } from '../Components/InternalList/Interfaces';

export interface ErrorsAndWarnings {
    logType: LogType;
    message: string;
    friendlyMessage: string;
    details: string;
    dateTime: Date;
    migrationStatus: number;
    occuredOnName: number;
}

export interface UserActionLog {
    message: string;
    name: string;
    email: string;
    created: Date;
}

export interface MigrationStatusHistory {
    migrationId: string;
    timeOfChange: Date;
    status: MigrationStatus;
}

export interface InternalMigration {
    id: string;
    customerId: string;
    customerNumber: string;
    customerName: string;
    organizationNumber: string;
    contactEmail: string;
    owner: any;
    assignedTo: any;
    aoMigration: any;
    errorMessage: any;
    status: number;
    statusName: string;
    failStatus: number;
    created: Date;
    changed: Date;
    startedAt: Date | null;
    type: number;
    sourceType: number;
    mainCustomer: any;
    files: UploadedFile[];
    eaConfiguration: {
        company: Company;
        collaborationOptions: CollaborationOption;
        variant: EaccountingVariant;
        agreementInvoicePeriod: number;
        sendStatus: SendStatus;
        competitor: Competitor; // remove in future cause obsolete
        competitorConfig: CompetitorConfig;
    };
    hasErrors: boolean;
    hasWarnings: boolean;
    collaborationStatus: number;
    collaborationId: string;
    consultantId: string;
    logs: ErrorsAndWarnings[];
    comments: MigrationComment[];
    aoCustomerName: string;
    guideCompleted: boolean;
    userActionLog: UserActionLog[];
    migrationStatusHistory: MigrationStatusHistory[];
    isAllowedToDownloadFiles: boolean;
}

export interface MigrationComment {
    id: number;
    migrationId: string;
    comment: string;
    dateStamp: Date;
    addedBy: string;
    addedByEmail: string;
    addedByName: string;
}

export interface PaginatedResult<T> {
    totalRows: number;
    offset: number;
    rows: T[];
    totalPages: number;
}

export interface InternalMigrationListModel {
    id: string;
    customerName: string;
    organizationNumber: string;
    status: MigrationStatus;
    assignedTo: any;
    changed: Date;
    type: MigrationType;
    sourceType: SourceType;
    comments: number;
    aoCustomerId: string;
    aoCustomerName: string;
    guideCompleted: boolean;
}

export interface MigrationState {
    internalMigrationList: InternalMigrationListModel[];
    internalMigration: InternalMigration;
    migrationCount: MigrationCount[];
    vonCustomer: VonCustomer | null;
    totalRows: number;
    offset: number;
    totalPages: number;
    internalMigrationSeachList: InternalMigrationListModel[];
    paginationProps: PaginationProps;
}

export interface VonCustomer {
    customerNumber: string;
    customerCategory: string;
    name: string;
    organizationNumber: string;
}

export interface MigrationCount {
    status: MigrationStatus;
    count: number;
}

const initialState: MigrationState = {
    internalMigrationList: [],
    internalMigration: {} as InternalMigration,
    migrationCount: [],
    vonCustomer: null,
    totalRows: 0,
    offset: 0,
    totalPages: 0,
    internalMigrationSeachList: [],
    paginationProps: {
        status: 'new',
        sortOrder: 'DESC',
        sortColumn: 'Changed',
        searchPhrase: '',
        offset: 0,
        rowsPerPage: 25,
        currentPageNumber: 1
    }
};

export const internalMigrationSlice = createSlice({
    name: 'internalMigration',
    initialState,
    reducers: {
        setInternalMigrations(state, action: PayloadAction<PaginatedResult<InternalMigrationListModel>>) {
            
            state.internalMigrationList = action.payload.rows;
            state.totalRows = action.payload.totalRows;
            state.offset = action.payload.offset;
            state.totalPages = action.payload.totalPages;
        },
        storeInternalMigrations: (state, action: PayloadAction<InternalMigrationListModel[]>) => {
            state.internalMigrationList = state.internalMigrationList.concat(action.payload);
        },
        storeInternalMigrationSearchList: (state, action: PayloadAction<InternalMigrationListModel[]>) => {
            state.internalMigrationSeachList = action.payload;
        },
        clearInternalMigrations: (state) => {
            state.internalMigrationList = [];
        },
        storeInternalMigration: (state, action: PayloadAction<InternalMigration>) => {
            state.internalMigration = action.payload;
        },
        storeMigrationComments: (state, action: PayloadAction<MigrationComment[]>) => {
            state.internalMigration.comments = action.payload;
        },
        storeLogs: (state, action: PayloadAction<ErrorsAndWarnings[]>) => {
            state.internalMigration.logs = action.payload;
        },
        storeUploadedFiles: (state, action: PayloadAction<UploadedFile[]>) => {
            state.internalMigration.files = action.payload;
        },
        storeMigrationCount: (state, action: PayloadAction<MigrationCount[]>) => {
            state.migrationCount = action.payload;
        },
        storeUserActionLog: (state, action: PayloadAction<UserActionLog[]>) => {
            state.internalMigration.userActionLog = action.payload;
        },
        storeVonCustomer: (state, action: PayloadAction<VonCustomer>) => {
            state.vonCustomer = action.payload;
        },
        storeMigrationStatusHistory: (state, action: PayloadAction<MigrationStatusHistory[]>) => {
            state.internalMigration.migrationStatusHistory = action.payload;
        },
        storePaginationProps: (state, action: PayloadAction<PaginationProps>) => {
            state.paginationProps = {
                ...state.paginationProps,
                ...action.payload
            };
        }
    },
});

export const {
    setInternalMigrations,
    storeInternalMigrations,
    storeInternalMigration,
    storeMigrationComments,
    storeLogs,
    storeUploadedFiles,
    clearInternalMigrations,
    storeMigrationCount,
    storeUserActionLog,
    storeVonCustomer,
    storeMigrationStatusHistory,
    storeInternalMigrationSearchList,
    storePaginationProps
} = internalMigrationSlice.actions;

export default internalMigrationSlice.reducer;
