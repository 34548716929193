import React, { useEffect, useRef, useState } from "react";

const DropdownToggle = ({ showDropdown, setShowDropdown, children }: any) => {
    const toggleElement = React.cloneElement(children, {
        onClick: () => setShowDropdown(!showDropdown)
    });
    return toggleElement;
};

const DropdownMenu = ({ list, onSelect, setShowDropdown, style }: any) => (
    <ul className="dropdown-menu" role="menu" style={style}>
        {list.map((item: any) => {
            return (
                <li key={item.id}>
                    <a onClick={() => {
                        onSelect(item);
                        setShowDropdown(false);
                    }} title={item.name}>{item.name}</a>
                </li>
            );
        })}
    </ul>
);

const DropdownButton = (props: any) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef} className={`dropdown m-0 ${showDropdown ? "open" : ""}`}>
            <DropdownButton.Toggle {...props} showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
            <DropdownButton.Menu {...props} setShowDropdown={setShowDropdown} style={props.menuStyle} /> {/* Ensure style prop is passed */}
        </div>
    );
};

DropdownButton.Toggle = DropdownToggle;
DropdownButton.Menu = DropdownMenu;

export default DropdownButton;