interface Props {
    message: string,
    type: 'error' | 'info' | 'warning',
    onClick: () => void
}

export default function Banner(props: Props) {
    return (
        <div className={`alert alert-${props.type}`} role="alert">
            <p>
                {props.message} {" "}
                <a
                    onClick={props.onClick}
                >
                    Klicka här
                </a>
            </p>
        </div>
    );
}

