import { useState } from "react";

interface Props {
    validationError: string;
}

export default function RenderInputError(props: Props) {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    function handleMouseEnter() {
        setTooltipVisible(true);
    }

    function handleMouseLeave() {
        setTooltipVisible(false);
    }

    return (
        <>
            <div
                className={`vismaicon vismaicon-sm vismaicon-filled vismaicon-error`}
                onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            >
                {isTooltipVisible && (
                    <div className="tooltip tooltip-error in right left-16 top-n16">
                        <div className="tooltip-arrow"></div>
                        <div className="tooltip-inner text-nowrap">{props.validationError}</div>
                    </div>
                )}
            </div>
        </>
    )
}

