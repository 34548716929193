import React, { useState } from "react";
import RenderInputError from "../SharedComponents/RenderInputError";

export default function DropDown(props: any) {
    function getIcon() {
        if (props.icon) {
            if (props.iconClick) {
                return <span className={`${props.icon} label-icon`} style={{ cursor: "pointer" }} onClick={props.iconClick} aria-hidden="true"></span>
            }
            return <span className={`${props.icon} label-icon`} aria-hidden="true"></span>
        }
    }

    return (
        <div className={`form-group ${props.error ? "has-error" : ""} full-width ${props.alignment} d-flex flex-column`}>
            <span className={`col-md-${props.elementWidth ? props.elementWidth : 6} col-xs-10`} style={{ whiteSpace: "nowrap" }}>
                {props.label && <label className={`col-md-${props.labelWidth ? props.labelWidth : 4} control-label col-xs-6`}>{props.label}{getIcon()}</label>}
            </span>
            <div className={`col-md-${props.elementWidth ? props.elementWidth : 6} col-xs-10 ${props.padding ? props.padding : ""}`}>
                <select id={props.id} disabled={props.disabled} className="form-control" value={props.value}
                    onChange={(value) => props.onChange(value.target.value)}>
                    {
                        props.blankOption && <option key={-1} value={""}>{props.blankValue ? props.blankValue : ""}</option>
                    }
                    {
                        props.list && props.list.map((item: any) => <option key={item.id} value={props.nameValued ? item.name : item.id}>
                            {props.nameAggregate ? props.nameAggregate(item) : item.name}</option>)
                    }
                    {
                        props.blankOptionLast && <option key={-1} value={-1}>{""}</option>
                    }
                </select>
                {props.error && (
                <>
                    <RenderInputError validationError={props.error} />
                </>
            )}
            </div>
            
        </div>
    )
}