import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureFlag, MigrationNamesAndIds } from '../Components/FeatureFlags/Interfaces';

interface FeatureFlagState {
    featureFlags: FeatureFlag[];
    selectedFeatureFlag?: FeatureFlag;
    availableFeatureFlags: string[];
    MigrationNamesAndIds: MigrationNamesAndIds[];
}

const initialState: FeatureFlagState = {
    featureFlags: [],
    selectedFeatureFlag: undefined,
    availableFeatureFlags: [],
    MigrationNamesAndIds: []
};

export const featureFlagSlice = createSlice({
    name: 'featureFlag',
    initialState,
    reducers: {
        setFeatureFlags: (state, action: PayloadAction<FeatureFlag[]>) => {
            state.featureFlags = action.payload;
        },
        addFlag: (state, action: PayloadAction<FeatureFlag>) => {
            state.featureFlags.push(action.payload);
        },
        updateFeatureFlag: (state, action: PayloadAction<FeatureFlag>) => {
            const index = state.featureFlags.findIndex(flag => flag.id === action.payload.id);
            if (index !== -1)
                state.featureFlags[index] = action.payload;
        },
        removeFeatureFlag: (state, action: PayloadAction<number>) => {
            state.featureFlags = state.featureFlags.filter(flag => flag.id !== action.payload);
        },
        removeFeatureFlags: (state, action: PayloadAction<number[]>) => {
            const idsToRemove = action.payload;
            state.featureFlags = state.featureFlags.filter(flag => flag.id !== undefined && !idsToRemove.includes(flag.id));
        },
        setSelectedFeatureFlag: (state, action: PayloadAction<FeatureFlag>) => {
            state.selectedFeatureFlag = action.payload;
        },
        setAvailableFeatureFlags: (state, action: PayloadAction<string[]>) => {
            state.availableFeatureFlags = action.payload;
        },
        setMigrationNamesAndIds: (state, action: PayloadAction<MigrationNamesAndIds[]>) => {
            state.MigrationNamesAndIds = action.payload;
        }
    },
});

export const {
    setFeatureFlags,
    addFlag,
    updateFeatureFlag,
    removeFeatureFlag,
    removeFeatureFlags,
    setSelectedFeatureFlag,
    setAvailableFeatureFlags,
    setMigrationNamesAndIds
} = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
