import LoadingIcon from "../Icons/LoadingIcon";
import {useNavigate} from "react-router-dom";
import {routes} from "../../Constants/routes";
import {SmbMigration} from "../../Reducers/userReducer";
import {useHelpers} from "../../../Utils/helpers";

import MigrationStatusDetails from "../SharedComponents/MigrationStatusDetails";
import {MigrationStatus, SourceType} from "../Enums/CommonEnums";
import {useFortnoxActionCreator} from "../../Actions/FortnoxActionCreator";
import Banner from "../Helpers/Banner";
import {modalTypes} from "../../Constants/modalTypes";
import {CreateTransferCollaborationsModal} from "../TransferCollaborations/CreateTransferCollaborationsModal";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {onToggleModal} from "../../Reducers/modalReducer";
import MigrationStopperModal from "./MigrationStopperModal";

export default function SMBSummarizedStatus(props: {
    migration: SmbMigration,
    onModalToggle: (ids: string[]) => void
}) {

    const fortnoxActions = useFortnoxActionCreator();
    const navigate = useNavigate();
    const migration = props.migration;

    const errorStatus = migration.currentStatus === MigrationStatus.ReuploadFiles || migration.currentStatus === MigrationStatus.Error;
    const newToHandle = migration.currentStatus === MigrationStatus.New || migration.currentStatus === MigrationStatus.FilesUploaded;
    const isOngoing = migration.currentStatus >= MigrationStatus.ReadyForMigration && migration.currentStatus < MigrationStatus.Done;

    // For now there are only stoppers for Lön
    const hasStoppers = migration.sourceType === SourceType.Lon && migration.customerErrors && migration.customerErrors.length > 0;
    const stopperMessage = "Du har inställningar som inte är kompatibla med Lön Smart.";

    const canModifyAdditionalInfo = (migration.currentStatus === MigrationStatus.ReuploadFiles || migration.currentStatus === MigrationStatus.Error) && migration.additionalInfoCount > 0 && migration.hasAdditionalInformationErrors;

    const activeModal = useAppSelector((state) => state.modal.modal);
    const dispatch = useAppDispatch();

    const reAuthorizeFortnox = (id: string) => {
        fortnoxActions.getAuthUriReconnect(id, window.location.href, true);
    }

    const helper = useHelpers();

    let statusIcon: any;
    let title: string;
    let header = "Att hantera (kräver åtgärd)";

    if (newToHandle) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit"}></i>
        title = "En ny att hantera"
    } else if (hasStoppers && errorStatus) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-error"}></i>
        title = "Stoppad - Det finns inställningar som inte är kompatibla med Lön Smart"
    } else if (canModifyAdditionalInfo) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit"}></i>
        title = "Stoppad - Komplettering behövs"
    } else if (!hasStoppers && errorStatus) {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-error"}></i>
        title = "Stoppad - Hanteras av Visma Spcs"
    } else if (isOngoing) {
        statusIcon = <LoadingIcon/>
        title = "Hanteras just nu av Visma Spcs"
        header = "Pågående"
    } else {
        statusIcon = <i className={"pr-4 vismaicon vismaicon-filled vismaicon-success"}></i>
        title = "Företag överfört"
        header = "Slutförd överföring"
    }

    function showMigrationStoppers() {
        dispatch(onToggleModal(modalTypes.migrationStopperModal));
    }

    return <>
        {activeModal === modalTypes.migrationStopperModal && <MigrationStopperModal errorList={migration.customerErrors}/>}
        <div className="panel">
            <div className="container-padding">
                <div>
                    <h2 className="font-weight-bold">{header}</h2>
                    <h3 className="font-weight-bold">{migration.customerName} {migration.organizationNumber} </h3>
                    <div
                        id={migration.currentStatus === MigrationStatus.Done ? "migCustomerSatisfaction" : ""}
                        className={`mb-8 rounded bg-primary shadow-30 container-padding`}
                    >
                        <div className="d-flex justify-content-between">
                        <span className="d-flex flex-column">
                            <div className="d-flex flex-row">
                                {statusIcon}
                                <p className="pl-4 pt-4 font-family-ubuntu-semibold">{title}</p>
                            </div>
                            <div className="mt-8">
                                <span
                                    className="badge badge-info mt-2">{helper.getSourceType(migration.sourceType)}</span>
                            </div>
                        </span>
                            <span className="d-flex ">
                            {migration.sourceType == SourceType.Fortnox && <button className="btn btn-default"
                                                                                   onClick={() => reAuthorizeFortnox(migration.id)}>Återanslut
                                till Fortnox</button>}
                                {migration.currentStatus <= MigrationStatus.ReuploadFiles &&
                                    <span className="vismaicon vismaicon-delete cursorpointer"
                                          onClick={() => props.onModalToggle([migration.id])}></span>}
                        </span>
                        </div>
                        <MigrationStatusDetails migration={migration}/>
                        {migration.sourceType === SourceType.CsvFiles && migration.currentStatus === MigrationStatus.Manual &&
                            <div className="alert alert-info">
                                <p>
                                    Behöver du ladda upp fler filer? <a href="#"
                                                                        onClick={() => navigate(`${routes.csvImport}`)}>Gå
                                    till uppladdning</a>
                                </p>
                            </div>}
                        {!hasStoppers && (migration.currentStatus === MigrationStatus.Done || migration.currentStatus === MigrationStatus.OnboardingCompleted || canModifyAdditionalInfo) && (
                            <div className="alert alert-info" role="alert">
                                <a
                                    onClick={() => {
                                        if (canModifyAdditionalInfo) {
                                            navigate(`${routes.additionalInfo(migration.id)}`);
                                        } else if (migration.currentStatus === MigrationStatus.Done) {
                                            navigate(`${routes.smbMigrationDetails(migration.sourceType.toString())}`);
                                        }
                                    }}
                                >
                                    {canModifyAdditionalInfo ? "Klicka här för att komplettera dina uppgifter" : "Klicka här för att se din logg"}
                                </a>
                            </div>
                        )}
                        {(newToHandle && migration.sourceType !== SourceType.CsvFiles) && (
                            <div className="alert alert-warning" role="alert">
                                <p>
                                    Du behöver gå igenom startguiden för att komma igång.{" "}
                                    <a
                                        onClick={() => {
                                            navigate(getGuideRoute(migration.sourceType));
                                        }}
                                    >
                                        Klicka här
                                    </a>
                                </p>
                            </div>
                        )}
                        {(hasStoppers &&
                            <Banner message={stopperMessage} type="error" onClick={showMigrationStoppers}/>)}
                    </div>
                </div>
            </div>
        </div>
    </>
}

function getGuideRoute(sourceType: SourceType) {
    if (sourceType === SourceType.Lon) {
        return routes.startGuideLonEA;
    }

    return routes.startGuideSMB(sourceType.toString());
}