import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bank } from './startguideReducer';

const requiredCustomerInfoFields: Array<keyof LonEaStartGuideObject['customerInfo']> = [
    'startDate',
    'endDate',
    'bank',
    'accountNumber'
];

export interface LonEaStartGuideObject {
    customerInfo: {
        startDate: Date | null;
        endDate: Date | null;
        bank: Bank;
        accountNumber: string;
    };
    isCustomerInfoComplete: boolean,
}

export interface LonEaStartGuideState {
    startGuide: LonEaStartGuideObject;
    loading: boolean;
}

const initialState: LonEaStartGuideState = {
    startGuide: {
        customerInfo: {
            startDate: null,
            endDate: null,
            bank: {
                id: "",
                name: ""
            },
            accountNumber: "",
        },
        isCustomerInfoComplete: false
    },
    loading: false
};

interface UpdateLonEaStartGuide {
    field: string;
    value: any;
}

function setProperty<T>(obj: Record<string, T>, key: string, value: T) {
    obj[key] = value;
}

export const lonEaStartGuideSlice = createSlice({
    name: 'lonEaStartGuide',
    initialState,
    reducers: {
        fetchLonEaStartGuideData: (state) => {
            state.loading = true;
        },
        setLonEaCustomerInfoValue: (state, action: PayloadAction<UpdateLonEaStartGuide>) => {
            const { field, value } = action.payload;
            setProperty(state.startGuide.customerInfo, field, value);
            state.startGuide.customerInfo = { ...state.startGuide.customerInfo };

            state.startGuide.isCustomerInfoComplete = requiredCustomerInfoFields.every(
                (field) => !!state.startGuide.customerInfo[field]
            );
        },
        setLonEaStartGuideValue: (state, action: PayloadAction<UpdateLonEaStartGuide>) => {
            setProperty(state.startGuide, action.payload.field, action.payload.value);
        }
    },
});

export const { fetchLonEaStartGuideData, setLonEaCustomerInfoValue, setLonEaStartGuideValue } = lonEaStartGuideSlice.actions;
export default lonEaStartGuideSlice.reducer;