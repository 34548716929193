import {createSlice, PayloadAction} from '@reduxjs/toolkit';


export interface VonCustomer {
    customerNumber: string,
    customerCategory: string,
    name: string,
    organizationNumber: string,
}

export interface TransferCollaborationsModel {
    id: string,
    customerNameFrom: string,
    orgNumberFrom: string,
    customerNameTo: string,
    orgNumberTo: string,
    status: TransferCollaborationsStatus,
    collaborations: CreateCollaborationRequest[],
    collaborationsResponse: CreateCollaborationsResponse,
    errors: TransferCollaborationsError[]
}

export interface CreateCollaborationsResponse {
    collaborations: Collaboration[],
    failedCollaborations: FailedCollaboration[],
}

export interface FailedCollaboration {
    collaboration: CreateCollaborationRequest,
    message: string
}

export interface CreateCollaborationRequest {
    client: Client
}

export enum TransferCollaborationsStatus {
    SaveCollaborations = 10,
    WaitingForConfirmation = 15,
    CreateNewCollaborations = 20,
    Done = 200,
    Error = 1000
}

export interface TransferCollaborationsError
{
    errorMessage: string
}

export interface Client {
    orgNo: string,
    name: string,
}

export interface Collaboration {
    clientCustomerName: string,
    clientCustomerOrganizationNumber: string,
}

export interface TransferCollaborationsState {
    transferCollaborationsList: TransferCollaborationsModel[],
    transferCollaborationsDetails: TransferCollaborationsModel,
    vonCustomerFrom: VonCustomer | null,
    vonCustomerTo: VonCustomer | null
}

const initialState: TransferCollaborationsState = {
    transferCollaborationsList: [],
    transferCollaborationsDetails: {} as TransferCollaborationsModel,
    vonCustomerFrom: null,
    vonCustomerTo: null
}

export const transferCollaborationsSlice = createSlice({
    name: 'transferCollaborations',
    initialState,
    reducers: {
        storeTransferCollaborations: (state, action: PayloadAction<TransferCollaborationsModel[]>) => {
            state.transferCollaborationsList = action.payload;
        },
        storeTransferCollaborationsDetails: (state, action: PayloadAction<TransferCollaborationsModel>) => {
            state.transferCollaborationsDetails = action.payload;
        },
        storeVonCustomerFrom: (state, action: PayloadAction<VonCustomer>) => {
            state.vonCustomerFrom = action.payload;
        },
        storeVonCustomerTo: (state, action: PayloadAction<VonCustomer>) => {
            state.vonCustomerTo = action.payload;
        },
        optimisticallySetTransferCollaborationStatus: (state, action: PayloadAction<TransferCollaborationsStatus>) => {
            state.transferCollaborationsDetails = {...state.transferCollaborationsDetails, status: action.payload}
        },
    },
});

export const {
    storeTransferCollaborations,
    storeTransferCollaborationsDetails,
    storeVonCustomerFrom,
    storeVonCustomerTo,
    optimisticallySetTransferCollaborationStatus,
} = transferCollaborationsSlice.actions;

export default transferCollaborationsSlice.reducer;