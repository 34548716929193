import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import Modal from "../Helpers/Modal";
import DropdownButton from "../Helpers/DropdownButton";
import { useFeatureFlagActionCreator } from "../../Actions/FeatureFlagActionCreator";

export default function MassDeleteFeatureFlagsModal() {
    const featureFlagAction = useFeatureFlagActionCreator();
    const availableFeatureFlags = useAppSelector((state) => state.featureFlag.availableFeatureFlags);
    const featureflags = useAppSelector((state) => state.featureFlag.featureFlags);
    const [featureFlag, setFeatureFlag] = useState({ id: 0, name: '' });

    useEffect(() => {
        featureFlagAction.getAvailableFeatureFlags();
    }, []);

    const sortedAvailableFeatureFlags = availableFeatureFlags
        .map((name, index) => ({ id: index, name }))
        .sort((a, b) => {
            const aIsNumber = /^\d/.test(a.name);
            const bIsNumber = /^\d/.test(b.name);
            return aIsNumber && bIsNumber ? a.name.localeCompare(b.name, undefined, { numeric: true }) :
                aIsNumber ? -1 : bIsNumber ? 1 : a.name.localeCompare(b.name);
        });

    const onSelectFeatureFlag = (flag: { id: number, name: string }) => {
        setFeatureFlag({ id: flag.id, name: flag.name });
    };

    const deleteFeatureFlags = (flagName: string) => {
        const flagsToDelete = featureflags
            .filter(flag => flag.name === flagName)
            .map(flag => flag.id)
            .filter(id => id !== undefined) as number[];

        featureFlagAction.deleteFeatureFlags(flagsToDelete);
    };

    const renderFeatureFlagDropdown = () => {
        return (
            <>
                <DropdownButton list={sortedAvailableFeatureFlags} onSelect={onSelectFeatureFlag}>
                    <button type="button" className="btn dropdown-toggle w-100 pr-0 mr-0">
                        {featureFlag.name === '' ? "Välj" : featureFlag.name}
                    </button>
                </DropdownButton>
            </>
        );
    };

    return (
        <>
            <Modal title="Ta bort alla tillhörande flaggor"
                modalBodyStyle={{ overflow: 'visible' }}
                mainButtonTitle="Radera"
                onSave={() => deleteFeatureFlags(featureFlag.name)}
                mainButtonDisabled={featureFlag.name === ''}
                closeAfterSave={true}
            >
                <div className="d-flex flex-column pt-32 pr-64 pl-64">
                    <label className="pb-12">Välj flagga att radera</label>
                    {renderFeatureFlagDropdown()}
                </div>
                <div className="alert alert-info mt-24" role="alert">
                    Tänk på att alla tillhörande flaggor kommer att raderas. Detta går inte att ångra.
                </div>
            </Modal>
        </>
    )
}