import React, { useRef, useState } from 'react';

interface ListSearchProps {
    placeholder: string;
    id: string;
    onSearch: (searchTerm: string) => void;
    activateOnEnterOrBlur?: boolean;
    clearAction?: () => void;
}

const ListSearch: React.FC<ListSearchProps> = ({ placeholder, id, onSearch, activateOnEnterOrBlur = false, clearAction }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const clearButtonClicked = useRef(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        if (!activateOnEnterOrBlur) {
            onSearch(event.target.value);
        }
    };

    const handleSearch = () => {
        onSearch(searchTerm);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter')
            handleSearch();
        else if (event.key === 'Escape')
            onClear();
    };

    const onClear = () => {
        clearButtonClicked.current = true;
        setSearchTerm('');
        onSearch('');

        if (clearAction)
            clearAction();
    };

    const handleBlur = () => {
        if (!clearButtonClicked.current) {
            handleSearch();
        }
        clearButtonClicked.current = false;
    };

    return (
        <div className="form-group search-group search-group-button mb-0">
            <label className="sr-only">Search</label>
            <input
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={activateOnEnterOrBlur ? handleKeyDown : undefined}
                onBlur={activateOnEnterOrBlur ? handleBlur : undefined}
                id={id}
                className="form-control"
            />
            <span className="clear-search show list-search-clear" onClick={onClear}></span>
        </div>
    );
};

export default ListSearch;
