import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MigrationStatus, SourceType } from "../Components/Enums/CommonEnums";
import {ErrorsAndWarnings} from "./internalMigrationReducer";


export interface MigrationList{
    aoMigrations: AoMigration[],
    smbMigrations: SmbMigration[]
}

export interface AoMigrationCount {
    new: number;
    collaboration: number;
    ongoing: number;
    success: number;
    error: number;
}

export interface AoMigration {
    customerId: string;
    customerNumber: number;
    customerName: string;
    organizationNumber: string;
    owner: User;
    hasAOSimplicity: boolean;
    aoMigrationCount: AoMigrationCount;
}

export interface SmbMigration {
    id: string,
    customerId: string;
    customerNumber: number;
    customerName: string;
    organizationNumber: string;
    owner: User;
    // status: string;
    sourceType: SourceType;
    additionalInfoCount: number;
    currentStatus: MigrationStatus;
    failedStatus: number;
    hasAdditionalInformationErrors: boolean;
    customerErrors: ErrorsAndWarnings[];
}

export enum UserRole {
    ReadOnly = 0,
    ReadWrite = 1,
    Admin = 2
}

export interface User {
    id: string;
    name : string;
    email : string;
    role : UserRole;
}

export interface UserState {
    userList: User[],
    currentUser: User,
    aoCustomers: AoMigration[],
    smbCustomers: SmbMigration[],
    internalUsers: User[]
}

const initialState : UserState = {
    userList: [],
    currentUser: {} as User,
    aoCustomers: [],
    smbCustomers: [],
    internalUsers: []
};

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        storeUsers: (state, action:PayloadAction<User[]>) => {
            state.userList = action.payload;
        },
        storeCurrentUser: (state, action:PayloadAction<User>) => {
            state.currentUser = action.payload;
        },
        storeCustomers: (state, action:PayloadAction<MigrationList>) => {
            state.aoCustomers = action.payload.aoMigrations;
            state.smbCustomers = action.payload.smbMigrations;
        },
        storeInternalUsers: (state, action: PayloadAction<User[]>) => {
            state.internalUsers = action.payload;
        }
    },
});

export const { storeUsers, storeCurrentUser, storeCustomers, storeInternalUsers } = userSlice.actions;
export default userSlice.reducer;

