import { useEffect, useState } from "react";
import Modal from "../Helpers/Modal";
import { FeatureFlag } from "./Interfaces";
import { useAppSelector } from "../../hooks";
import { useInternalActionCreator } from "../../Actions/InternalActionCreator";
import DropdownButton from "../Helpers/DropdownButton";
import { SourceType } from "../Enums/CommonEnums";
import { User } from "../../Reducers/userReducer";
import { useFeatureFlagActionCreator } from "../../Actions/FeatureFlagActionCreator";


export default function AddFeatureFlagModal() {
    const featureFlagAction = useFeatureFlagActionCreator();
    const availableFeatureFlags = useAppSelector((state) => state.featureFlag.availableFeatureFlags);
    const internalUsers = useAppSelector((state) => state.users.internalUsers);
    const customers = useAppSelector((state) => state.featureFlag.MigrationNamesAndIds);
    const transformedCustomers = customers
        .filter((customer) => customer.customerName !== "Anonymized")
        .map((customer) => ({
            id: customer.id,
            name: customer.customerName
        }));
    const [featureFlag, setFeatureFlag] = useState<FeatureFlag>({
        id: undefined,
        name: '',
        migrationId: undefined,
        sourceType: undefined,
        ownerId: undefined,
        enabled: true,
        isCommonFlag: false
    });

    const menuStyle = {
        maxHeight: '350px',
        overflowY: 'auto'
    };

    useEffect(() => {
        featureFlagAction.getAvailableFeatureFlags();
    }, []);

    const sortByName = <T extends { name: string }>(array: T[]): T[] =>
        array.sort((a, b) => {
            const aIsNumber = /^\d/.test(a.name);
            const bIsNumber = /^\d/.test(b.name);
            return aIsNumber && bIsNumber ? a.name.localeCompare(b.name, undefined, { numeric: true }) :
                aIsNumber ? -1 : bIsNumber ? 1 : a.name.localeCompare(b.name);
        });

    const sortedAvailableFeatureFlags = availableFeatureFlags
        .map((name, index) => ({ id: index, name }))
        .sort((a, b) => {
            const aIsNumber = /^\d/.test(a.name);
            const bIsNumber = /^\d/.test(b.name);
            return aIsNumber && bIsNumber ? a.name.localeCompare(b.name, undefined, { numeric: true }) :
                aIsNumber ? -1 : bIsNumber ? 1 : a.name.localeCompare(b.name);
        });

    const sortedInternalUsers = sortByName([...internalUsers]);

    const sortedCustomers = sortByName([...transformedCustomers]);

    const onSelectFeatureFlag = (flag: { id: number, name: string }) => {
        setFeatureFlag({ ...featureFlag, name: flag.name });
    };

    const onSelectSourceType = (sourceType: { id: number, name: string }) => {
        setFeatureFlag({ ...featureFlag, sourceType: sourceType.id });
    };

    const onSelectOwner = (owner: User) => {
        setFeatureFlag({ ...featureFlag, ownerId: owner.id });
    };

    const onSelectIsCommonFlag = (option: { id: boolean, name: string }) => {
        setFeatureFlag({ ...featureFlag, isCommonFlag: option.id });
    };

    const onSelectMigration = (migration: { id: string, customerName: string }) => {
        setFeatureFlag({ ...featureFlag, migrationId: migration.id });
    };

    const renderFeatureFlagDropdown = () => {
        return (
            <>
                <DropdownButton list={sortedAvailableFeatureFlags} onSelect={onSelectFeatureFlag}>
                    <button type="button" className="btn dropdown-toggle w-100 pr-0 mr-0">
                        {featureFlag.name === '' ? "Välj" : featureFlag.name}
                    </button>
                </DropdownButton>
            </>
        );
    };

    const getSourceTypeList = () => {
        return Object.keys(SourceType)
            .filter(key => isNaN(Number(key))) // Filter out numeric keys
            .map((key, index) => ({
                id: SourceType[key as keyof typeof SourceType],
                name: key
            }));
    };

    const renderSourceTypeDropdown = () => {
        const sourceTypeList = getSourceTypeList();

        return (
            <>            
                <DropdownButton list={sourceTypeList} onSelect={onSelectSourceType}>
                    <button type="button" className="btn w-100 dropdown-toggle">
                        {featureFlag.sourceType === undefined ? "Välj" : SourceType[featureFlag.sourceType]}
                    </button>
                </DropdownButton>
            </>
        );
    };

    const renderUsersDropdown = () => {
        return (
            <>
                <DropdownButton list={sortedInternalUsers} onSelect={onSelectOwner} menuStyle={menuStyle}>
                    <button type="button" className="btn w-100 dropdown-toggle">
                        {featureFlag.ownerId === undefined ? "Välj ägare" : internalUsers.find(user => user.id === featureFlag.ownerId)?.name}
                    </button>
                </DropdownButton>
            </>
        );
    };

    const renderIsCommonFlagDropdown = () => {
        const options = [
            { id: true, name: "Ja" },
            { id: false, name: "Nej" }
        ];

        return (
            <>
                <DropdownButton list={options} onSelect={onSelectIsCommonFlag} menuStyle={menuStyle}>
                    <button type="button" className="btn w-100 dropdown-toggle">
                        {featureFlag.isCommonFlag ? "Ja" : "Nej"}
                    </button>
                </DropdownButton>
            </>
        );
    };

    const renderCustomersDropdown = () => {

        return (
            <>
                <DropdownButton list={sortedCustomers} onSelect={onSelectMigration} menuStyle={menuStyle}>
                    <button type="button" className="btn w-100 dropdown-toggle">
                        {featureFlag.migrationId === undefined ? "Välj" : transformedCustomers.find(migration => migration.id === featureFlag.migrationId)?.name}
                    </button>
                </DropdownButton>
            </>
        )
    };

    const flagCanBeAdded = featureFlag.name !== '' && (featureFlag.sourceType !== undefined || featureFlag.ownerId !== undefined || featureFlag.isCommonFlag !== false || featureFlag.migrationId !== undefined);

    return (
        <Modal title="Lägg till ny featureflag" mainButtonDisabled={!flagCanBeAdded} mainButtonTitle={"Lägg till"}
            onSave={() => featureFlagAction.addFeatureFlag(featureFlag)} closeAfterSave={true} size="modal-md" modalBodyStyle={{ overflow: 'visible' }} >
            <div className="form-group my-16 pr-32 pl-32 d-flex flex-column">
                <label>Välj flagga (obligatorisk)</label>
                {renderFeatureFlagDropdown()}
                <div className="form-group">
                    <div className="d-flex flex-column">
                        <label>Gäller samtliga användare?</label>
                        {renderIsCommonFlagDropdown()}
                    </div>
                    <div className="d-flex flex-column">
                        <label>Specifik flytt</label>
                        {renderCustomersDropdown() }
                    </div>
                    <div className="d-flex flex-column">
                        <label>Typ av flytt / SourceType</label>
                        {renderSourceTypeDropdown()}
                    </div>
                    <div className="d-flex flex-column">
                        <label>Ägare</label>
                        {renderUsersDropdown()}
                    </div>
                </div>
            </div>
        </Modal >
    );
}