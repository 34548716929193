import React from "react";
import Modal from "../Helpers/Modal";
import {modalSlice} from "../../Reducers/modalReducer";
import { useAppDispatch } from "../../hooks";
import {ErrorsAndWarnings} from "../../Reducers/internalMigrationReducer";

interface Props {
    errorList: ErrorsAndWarnings[];
}

export default function MigrationStopperModal(props: Props) {
    const dispatch = useAppDispatch();
    const onCloseModal = () => dispatch(modalSlice.actions.onToggleModal(""))
    
    return <Modal title="Att hantera (kräver åtgärd)" size="modal-lg" hideButtons={true} style={{ height: "400px", overflowY: "auto"}}>
        <div className="d-flex flex-row">
            <div className="vismaicon vismaicon-lg vismaicon-filled vismaicon-error pt-24"></div>
            <div className="p-24">
                <h4 className="bold">Följande inställningar är inte kompatibla med Lön Smart</h4>
                <ul style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {props.errorList.map((error, index) => (
                        <li key={index}>{error.message}</li>
                    ))}
                </ul>
            </div>

        </div>
        <div className="modal-footer">
            <button className="btn btn-secondary float-right" onClick={() => onCloseModal()}>Stäng</button>
        </div>
    </Modal>
}