import {useTransferCollaborationsActionCreator} from "../../Actions/TransferCollaborationsActionCreator";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useAppSelector} from "../../hooks";
import {
    Collaboration,
    CreateCollaborationRequest,
    FailedCollaboration,
    TransferCollaborationsError,
    TransferCollaborationsStatus
} from "../../Reducers/transferCollaborationsReducer";


export default function TransferCollaborationsDetails() {

    const {id} = useParams();

    const transferCollaborationsActions = useTransferCollaborationsActionCreator();

    const transferCollaborations = useAppSelector((state) => state.transferCollaborations.transferCollaborationsDetails);

    useEffect(() => {
        transferCollaborationsActions.getTransferCollaborationsDetails(id)
    }, []);

    function continueTransferCollaborations(id: string) {
        transferCollaborationsActions.continueTransferCollaborations(id)
    }

    return <>
        <div className="panel container-padding">
            <div className="panel-heading">
                <h2>Överlåtelse av samarbeten</h2>
            </div>
            <div className="container-padding d-flex direction-column justify-content-between"
                 style={{columnGap: "2em"}}>
                <section className="panel bg-primary rounded-10 shadow-30 container-padding">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h4 className="text-primary font-weight-bold">Status</h4>
                            <p>{getStatusName(transferCollaborations.status)}</p>
                            <h4 className="text-primary font-weight-bold">Från organisationsnummer</h4>
                            <p>{transferCollaborations.orgNumberFrom}</p>
                            <h4 className="text-primary font-weight-bold">Till organisationsnummer</h4>
                            <p>{transferCollaborations.orgNumberTo}</p>
                        </div>
                    </div>
                </section>
                <section className="panel bg-primary rounded-10 shadow-30 container-padding d-flex"
                         style={{flexGrow: 1}}>
                    {transferCollaborations.status == TransferCollaborationsStatus.SaveCollaborations && (
                        <SaveCollaborationsStatus/>
                    )}
                    {transferCollaborations.status == TransferCollaborationsStatus.WaitingForConfirmation && (
                        <WaitingForConfirmationStatus
                            continueHandler={() => continueTransferCollaborations(transferCollaborations.id)}/>
                    )}
                    {transferCollaborations.status == TransferCollaborationsStatus.CreateNewCollaborations && (
                        <CreateNewCollaborationsStatus/>
                    )}
                    {transferCollaborations.status == TransferCollaborationsStatus.Done && (
                        <DoneStatus/>
                    )}
                </section>
            </div>
            {transferCollaborations.status == TransferCollaborationsStatus.WaitingForConfirmation && (
                <PendingCollaborationsTable collaborations={transferCollaborations.collaborations} errors={transferCollaborations.errors}/>
            )}
            {transferCollaborations.status == TransferCollaborationsStatus.Done && (
                <>
                    <FailedCollaborationsTable
                        failedCollaborations={transferCollaborations.collaborationsResponse?.failedCollaborations}/>
                    <SuccessfulCollaborationsTable
                        collaborations={transferCollaborations.collaborationsResponse?.collaborations}/>
                </>
            )}
        </div>
    </>
}

function SaveCollaborationsStatus() {
    return (<div className="">
        <h4 className="text-primary font-weight-bold">
            Samarbeten sparas.
        </h4>
    </div>);
}

function WaitingForConfirmationStatus(props: { continueHandler: () => void }) {
    return (<>
        <div className="" style={{flexGrow: 1}}>
            <h4 className="text-primary font-weight-bold">
                Samarbeten är nu sparade.
                Om du avslutat alla samarbeten kan du nu fortsätta överlåtelsen genom att trycka på
                Fortsätt
            </h4>
        </div>
        <div className="d-flex justify-content-end">
            <div className="align-self-end">
                <button className="btn btn-primary" onClick={props.continueHandler}>
                    Fortsätt
                </button>
            </div>
        </div>
    </>);
}

function CreateNewCollaborationsStatus() {
    return (<div className="">
        <h4 className="text-primary font-weight-bold">
            Väntar på att samarbeten ska överlåtas.
        </h4>
    </div>);
}

function DoneStatus() {
    return (<div>
        <h4 className="text-primary font-weight-bold">
            Överlåtelse slutförd!
        </h4>
    </div>);
}

function PendingCollaborationsTable(props: { collaborations: CreateCollaborationRequest[], errors: TransferCollaborationsError[] }) {
    const hasErrors = !!props.errors?.length;
    
    return (<>
        {hasErrors && (<>
            <div className="alert alert-warning d-flex justify-content-between align-items-center" role="alert">
                Fel har uppstått
            </div>
            <div className="table-responsive">
                <table className="table table-stripe table-regular">
                    <thead>
                    <tr>
                        <th>Felmeddelande</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.errors.map((item: TransferCollaborationsError, index: number) => {
                            return <tr key={index}>
                                <td>{item.errorMessage}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </>)}
        <h3>Sparade samarbeten</h3>
        <div className="table-responsive">
            <table className="table table-stripe table-regular">
                <thead>
                <tr>
                    <th>Orgnummer</th>
                    <th>Namn</th>
                </tr>
                </thead>
                <tbody>
                {!props.collaborations?.length ? (
                    <tr>
                        <td></td>
                        <td>
                            <p>Ingen data finns tillgänglig</p>
                        </td>
                    </tr>
                ) : (
                    props.collaborations.map((item: CreateCollaborationRequest, index: number) => {
                        return <tr key={index}>
                            <td>{item.client.orgNo}</td>
                            <td>{item.client.name}</td>
                        </tr>
                    })
                )
                }
                </tbody>
            </table>
        </div>
    </>);
}

function SuccessfulCollaborationsTable(props: { collaborations: Collaboration[] }) {
    return (<>
        <h3>Lyckade överlåtelser</h3>
        <div className="table-responsive">
            <table className="table table-stripe table-regular">
                <thead>
                <tr>
                    <th>Orgnummer</th>
                    <th>Namn</th>
                </tr>
                </thead>
                <tbody>
                {!props.collaborations?.length ? (
                    <tr>
                        <td></td>
                        <td>
                            <p>Ingen data finns tillgänglig</p>
                        </td>
                    </tr>
                ) : (
                    props.collaborations.map((item: Collaboration, index: number) => {
                        return <tr key={index}>
                            <td>{item.clientCustomerOrganizationNumber}</td>
                            <td>{item.clientCustomerName}</td>
                        </tr>
                    })
                )
                }
                </tbody>
            </table>
        </div>
    </>);
}

function FailedCollaborationsTable(props: { failedCollaborations: FailedCollaboration[] }) {
    const allTransfersSuccessful = !props.failedCollaborations?.length;

    if (allTransfersSuccessful) {
        return (
            <div className="alert alert-success d-flex justify-content-between align-items-center" role="alert">
                Alla överlåtelser lyckades{/*behöver kanske omformuleras*/}
            </div>
        );
    }

    return (<>
        <div className="alert alert-warning d-flex justify-content-between align-items-center" role="alert">
            En eller flera överlåtelser misslyckades
        </div>
        <h3>Misslyckade överlåtelser</h3>
        <div className="table-responsive">
            <table className="table table-stripe table-regular">
                <thead>
                <tr>
                    <th>Orgnummer</th>
                    <th>Namn</th>
                    <th>Meddelande</th>
                </tr>
                </thead>
                <tbody>
                {props.failedCollaborations.map((item: FailedCollaboration, index: number) => {
                    return <tr key={index}>
                        <td>{item.collaboration.client.orgNo}</td>
                        <td>{item.collaboration.client.name}</td>
                        <td>{item.message}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    </>);
}

function getStatusName(status: TransferCollaborationsStatus) {
    switch (status) {
        case TransferCollaborationsStatus.SaveCollaborations:
            return "Sparar samarbeten";
        case TransferCollaborationsStatus.WaitingForConfirmation:
            return "Inväntar bekräftelse";
        case TransferCollaborationsStatus.CreateNewCollaborations:
            return "Skapar nya samarbeten";
        case TransferCollaborationsStatus.Done:
            return "Slutförd";
        case TransferCollaborationsStatus.Error:
            return "Något har gått fel";
    }
}